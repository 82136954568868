import React from "react";
import SpotlightContainer from "../spotlight";
import UiPath from "../../../images/uipath.png";
import Image from "../../../images/pa2.png";

function Project() {
  const jobData = {
    jobName: "SAP Playground Builder",
    button: "Download",
    link: "https://drive.google.com/drive/folders/11N-WU9Hv-ysSQUr4AW3Qo_tUpNi2eOcx?usp=sharing",
    image: Image,
    stack: [
      {
        name: "UiPath",
        image: UiPath,
      },
    ],

    description:
      "This is a RPA bot using UiPath designed to create mini applications within the SAP Playground environment. This project is a redacted version of a more extensive RPA solution originally developed for processing invoices on Oracle SAP for an oil and gas client. The bot simplifies the process by automating the creation of mini apps, allowing for efficient testing and development within SAP’s sandbox environment.",
    roles: [],
  };

  return <SpotlightContainer jobData={jobData} />;
}
export default Project;
